import React from "react";
import { withRouter } from "next/router";
import Header from "../Header/Header";
import ShopHeader from "../Header/ShopHeader";
import AppWrapper, { ContentWrapper } from "./Layout.styled";

const Layout: React.FunctionComponent<{ router?: any }> = ({
  router,
  children,
}) => {
  return (
    <AppWrapper
    // flexDirection row if desktop, column if mobile
    >
      {children?.props?.protected && children?.props?.userTypes == "admin" && (
        <Header />
      )}
      {children?.props?.protected &&
        children?.props?.userTypes == "customer" && <ShopHeader />}
      <ContentWrapper>{children}</ContentWrapper>
    </AppWrapper>
  );
};

export default withRouter(Layout);
