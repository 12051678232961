import React, { useState, useEffect, useContext, createContext } from "react";

import {
  query,
  limit,
  where,
  collection,
  getDocs,
  db,
} from "../firebase/config";

// function to get the domain name from clientId
export const getDomainInfo = async (clientId) => {
  // find data in the collection domain where clientId = clientId
  const domainQuery = query(
    collection(db, "domains"),
    where("clientId", "==", clientId),
    limit(1)
  );

  // get the data
  const domainSnapshot = await getDocs(domainQuery);
  const domainList = domainSnapshot.docs.map((doc) => doc.data());

  // return the data
  return domainList[0];
};

export const convertDate = (current_period_end) => {
  let date = new Date(current_period_end * 1000);
  let current_period_end_date = date.getDate();
  let current_period_end_month = date.toLocaleString("default", {
    month: "short",
  });

  return `${current_period_end_date} ${current_period_end_month}`;
};

export const getDateFormat = (subscription) => {
  return `${subscription?.plan?.interval_count} ${
    subscription?.plan?.interval
  }${subscription?.plan?.interval_count > 1 ? "s" : ""}`;
};

export const pageview = (url) => {
  if (window !== undefined) {
    window.gtag("config", "G-4R7RLQ35WM", {
      page_path: url,
    });
  }
};

export const event = ({ action, params }) => {
  if (window !== undefined) {
    window.gtag("event", action, params);
  }
};
