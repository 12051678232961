import React, { useState, useEffect, useContext, createContext } from "react";

import { getAuth } from "firebase/auth";
import {
  deleteDoc,
  collection,
  updateDoc,
  getDocs,
  getDoc,
  db,
  setDoc,
  doc,
  query,
  where,
  limit,
  storage,
} from "../firebase/config";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { useAuth } from "./useAuth";

const SubscriptionContext = createContext();

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useSubscription = () => {
  return useContext(SubscriptionContext);
};

// Provider hook that creates auth object and handles state
export const SubscriptionProvider = ({ children }) => {
  const { user } = useAuth();

  const [subscriptions, setSubscriptions] = useState([]);
  const [itemLoading, setItemLoading] = useState(false);

  const getAllSubscriptionByClientId = async (customerId) => {
    try {
      const q = query(
        collection(db, `subscriptions`),
        where("customerId", "==", customerId)
      );

      const querySnapshot = await getDocs(q);
      let data = [];
      querySnapshot.forEach((doc) => {
        data.push({ ...doc.data(), id: doc.id });
      });

      setSubscriptions(data);
    } catch (e) {
      return { error: true, data: e };
    }
  };

  const getSubscriptionById = async (id) => {
    try {
      const docRef = doc(db, "subscriptions", id);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        return { error: false, data: docSnap.data() };
      } else {
        // doc.data() will be undefined in this case

        return { error: true, data: "No such document!" };
      }
    } catch (e) {
      return { error: true, data: e };
    }
  };

  const updateSubscription = async (id, data) => {
    try {
      const docRef = doc(db, "subscriptions", id);
      await updateDoc(docRef, data);
      return { error: false, data: "Document successfully updated!" };
    } catch (e) {
      return { error: true, data: e };
    }
  };

  const getSubscriptionByCustomerId = async (customerId) => {
    try {
      const q = query(
        collection(db, `subscriptions`),
        where("customerId", "==", customerId)
      );

      const querySnapshot = await getDocs(q);
      let data = [];
      querySnapshot.forEach((doc) => {
        data.push({ ...doc.data(), id: doc.id });
      });

      setSubscriptions(data);
    } catch (e) {
      return { error: true, data: e };
    }
  };

  const getAllSubscriptionByUserId = async (customerStripeIds) => {
    try {
      let data = [];
      for (let i = 0; i < customerStripeIds.length; i++) {
        const q = query(
          collection(db, `subscriptions`),
          where("customerId", "==", customerStripeIds[i].stripeAccountId)
        );
        const querySnapshot = await getDocs(q);

        querySnapshot.forEach((doc) => {
          data.push({ ...doc.data(), id: doc.id });
        });
      }
      setSubscriptions(data);
    } catch (e) {
      return { error: true, data: e };
    }
  };

  const UpdateItem = async (item, id, index, type) => {
    setItemLoading(true);

    try {
      // update subscriptions
      const newSubscriptions = subscriptions.map((sub) => {
        if (sub.id === subscriptions[id].id) {
          return {
            ...sub,
            product: {
              ...sub.product,
              items: sub.product.items.map((item, i) => {
                if (i === index) {
                  if (type === "add") {
                    return {
                      ...item,
                      quantity: item.quantity + 1,
                    };
                  } else {
                    return {
                      ...item,
                      quantity: item.quantity - 1,
                    };
                  }
                }
                return item;
              }),
            },
          };
        }
        return sub;
      });

      // update firestore
      const docRef = doc(db, "subscriptions", subscriptions[id].id);
      await updateDoc(docRef, {
        product: {
          ...subscriptions[id].product,
          items: subscriptions[id].product.items.map((item, i) => {
            if (i === index) {
              if (type === "add") {
                return {
                  ...item,
                  quantity: item.quantity + 1,
                };
              } else {
                return {
                  ...item,
                  quantity: item.quantity - 1,
                };
              }
            }
            return item;
          }),
        },
      });

      setSubscriptions(newSubscriptions);
      setItemLoading(false);
    } catch (e) {
      setItemLoading(false);

      return { error: true, data: e };
    }
  };

  useEffect(() => {}, [user]);

  const values = {
    subscriptions,
    getAllSubscriptionByClientId,
    getSubscriptionById,
    updateSubscription,
    getSubscriptionByCustomerId,
    getAllSubscriptionByUserId,
    UpdateItem,
    itemLoading,
  };

  return (
    <SubscriptionContext.Provider value={values}>
      {children}
    </SubscriptionContext.Provider>
  );
};
