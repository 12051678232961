import React, { useState, useEffect, useContext, createContext } from "react";
import {
  deleteDoc,
  collection,
  updateDoc,
  getDocs,
  getDoc,
  db,
  setDoc,
  doc,
  query,
  where,
  limit,
  storage,
} from "../firebase/config";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { useAuth } from "./useAuth";

const ProductContext = createContext();

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useProduct = () => {
  return useContext(ProductContext);
};

// Provider hook that creates auth object and handles state
export const ProductProvider = ({ children }) => {
  const { currentUser } = useAuth();

  const [products, setProducts] = useState([]);

  // get shop details on load

  const getProductKey = async (tenantId) => {
    const productRef = doc(collection(db, `${tenantId}/products/products`));
    return productRef.id;
  };

  const createProduct = async (id, data) => {
    try {
      let res = await setDoc(doc(db, `products/${id}`), data);
      // add product to products array
      setProducts([...products, { ...data, id }]);
      return { error: false, data: res };
    } catch (e) {
      console.error("Error adding document: ", e);
      return { error: true, data: e };
    }
  };

  const updateProduct = async (slug, product) => {
    try {
      let res = await updateDoc(doc(db, `products/${slug}`), product, {
        merge: true,
      });

      //  update to products to reflect changes
      const updatedProducts = products.map((p) => {
        if (p.id === slug) {
          return { ...p, ...product };
        }
        return p;
      });

      setProducts(updatedProducts);

      return { error: false, data: res };
    } catch (e) {
      console.error("Error adding document: ", e);
      return { error: true, data: e };
    }
  };

  const getAllProductByClientId = async (clientId) => {
    try {
      const q = query(
        collection(db, "products"),
        where("clientId", "==", clientId)
      );
      const querySnapshot = await getDocs(q);
      const data = [];
      querySnapshot.forEach((doc) => {
        data.push({ ...doc.data(), id: doc.id });
      });
      setProducts(data);
    } catch (e) {
      return { error: true, data: e };
    }
  };

  const getProduct = async (id) => {
    try {
      const docRef = doc(db, `products/${id}`);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        return { error: false, data: docSnap.data() };
      }
      return { error: true, data: false };
    } catch (e) {
      console.error("Error adding document: ", e);
      return { error: true, data: e };
    }
  };

  const uploadImageProduct = async (file) => {
    try {
      if (file.type === "image/jpeg" || file.type === "image/png") {
        const imageRef = ref(storage, `images/product/${file.name}`);
        const res = await uploadBytes(imageRef, file);
        const url = await getDownloadURL(res.ref);
        return { error: false, data: url };
      }
    } catch (error) {
      return { error: true, data: error };
    }
  };

  //   delete product
  const deleteProduct = async (id) => {
    try {
      const docRef = await deleteDoc(doc(db, `products/${id}`));
      const updatedProducts = products.filter((product) => product.id !== id);
      setProducts(updatedProducts);
      return { error: false, data: docRef };
    } catch (e) {
      console.error("Error adding document: ", e);
      return { error: true, data: e };
    }
  };

  useEffect(() => {
    if (currentUser) {
      getAllProductByClientId(currentUser.defaultClientId);
    }
    getAllProductByClientId();
  }, [currentUser]);

  const values = {
    getProductKey,
    createProduct,
    updateProduct,
    getAllProductByClientId,
    getProduct,
    products,
    uploadImageProduct,
    deleteProduct,
  };

  return (
    <ProductContext.Provider value={values}>{children}</ProductContext.Provider>
  );
};
