import React from "react";
import ActiveLink from "../../../UiElements/NavLink/NavLink";
import Menu, { MenuItem, MenuLink } from "./MainMenu.styled";
import { FiLogOut, FiSettings, FiActivity, FiHome } from "react-icons/fi";
import { AiFillShop, AiOutlineCreditCard } from "react-icons/ai";
import { IoIosPeople, IoMdPeople } from "react-icons/io";
import { Block } from "baseui/block";
import { useAuth } from "../../../../context/useAuth";
const menuItems = [
  {
    id: 1,
    label: "Dashboard",
    path: "/shop/dashboard",
    icon: <FiHome />,
  },
];
type MenuProps = {
  className?: string;
  onClick?: () => void;
};

const MainMenu = ({ className, onClick }: MenuProps) => {
  const { logout } = useAuth();
  return (
    <Block
      overrides={{
        Block: {
          style: ({ $theme }) => {
            return {
              display: "flex",
              flexDirection: "column",
              height: "100%",
            };
          },
        },
      }}
    >
      <Menu className={className}>
        <Block className="default-header-logo">
          <MenuLink>monthlysubs.co</MenuLink>
        </Block>
        {menuItems.map((item) => (
          <MenuItem key={`top-menu--item${item.id}`} onClick={onClick}>
            <ActiveLink activeClassName="active" href={item.path}>
              <MenuLink>
                <Block
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  marginRight="scale200"
                >
                  {item.icon}
                </Block>
                {item.label}
              </MenuLink>
            </ActiveLink>
          </MenuItem>
        ))}
      </Menu>
      <Block
        overrides={{
          Block: {
            style: ({ $theme }) => {
              return {
                display: "flex",
                flexDirection: "column",
                padding: "0 34px",
                paddingTop: "calc(100vh - 150%)",
                justifyContent: "flex-end",
                "@media only screen and (max-width: 1135px)": {
                  display: "none",
                },
              };
            },
          },
        }}
      >
        <Block
          overrides={{
            Block: {
              style: ({ $theme }) => {
                return {
                  color: $theme.colors.white,
                };
              },
            },
          }}
        >
          <Block
            onClick={() => {
              logout();
            }}
            as="div"
            overrides={{
              Block: {
                style: ({ $theme }) => {
                  return {
                    display: "flex",
                    alignItems: "center",
                    // justifyContent: "center",
                    width: "100%",
                    backgroundColor: $theme.colors.primaryB,
                    padding: "10px",
                    cursor: "pointer",
                  };
                },
              },
            }}
          >
            <FiLogOut />
            <Block
              as="div"
              overrides={{
                Block: {
                  style: ({ $theme }) => {
                    return {
                      marginLeft: "10px",
                    };
                  },
                },
              }}
            >
              Logout
            </Block>
          </Block>
        </Block>
      </Block>
    </Block>
  );
};

export default MainMenu;
