import React, { useState } from "react";
import { FiLogOut, FiMenu, FiMoreHorizontal, FiHome } from "react-icons/fi";
import Sticky from "react-stickynode";
import { useRouter } from "next/router";
import { Button } from "baseui/button";
import { Drawer } from "baseui/drawer";
import { Block } from "baseui/block";

import MainMenu from "../HeaderMenu/MainMenu/MainMenu";

import { useAuth } from "../../../context/useAuth";
import HeaderWrapper, {
  PageTitle,
  TopMenuWrapper,
} from "./MobileHeader.styled";
import useVender from "../../../hooks/useVender";
import { getDomainInfo } from "../../../helpers";

const MobileHeader: React.FC<{}> = () => {
  const { currentUser } = useAuth();
  const vender = useVender(currentUser?.tenantId, currentUser?.defaultClientId);
  const [connectedAccount, setConnectedAccount] = React.useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [menu, setMenu] = useState("main");
  const { pathname } = useRouter();

  let pageName: React.ReactNode = pathname.split("/").slice(1, 2);
  let titleColor = "#000000";

  pageName =
    pathname !== "/" && pathname !== "/_error" ? (
      <PageTitle $style={{ color: titleColor }}>{pageName}</PageTitle>
    ) : null;

  let placement;
  if (menu === "main") {
    placement = "left";
  }
  if (menu === "top") {
    placement = "right";
  }

  React.useEffect(() => {
    const getConnectedAccount = async () => {
      const DomainInfo = await getDomainInfo(currentUser?.defaultClientId);
      if (currentUser && DomainInfo?.stripeAccount) {
        // verify if the account is connected
        fetch("/api/payment/connected_account", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            stripeAccount: DomainInfo?.stripeAccount,
          }),
        })
          .then((res) => res.json())
          .then((data) => {
            setConnectedAccount(data);
          });
      }
    };
    getConnectedAccount();
  }, [currentUser]);

  return (
    <Sticky>
      <HeaderWrapper className="mobile">
        <Button
          onClick={() => {
            setIsOpen(true);
            setMenu("main");
          }}
          kind="minimal"
          size="compact"
          overrides={{
            BaseButton: {
              style: ({ $theme }) => {
                return {
                  fontSize: "22px",
                  //   width: "30px",
                  justifyContent: "flex-start",
                  backgroundColor: "transparent",
                  position: "absolute",
                  left: "0",
                  top: "12px",
                  color: $theme.colors.white,
                  ":hover": {
                    backgroundColor: $theme.colors.primaryB,
                  },
                  ":focus": {
                    backgroundColor: $theme.colors.primaryB,
                  },
                };
              },
            },
          }}
        >
          <FiMenu />
        </Button>

        <PageTitle>Welcome {vender?.vender?.domain}</PageTitle>
      </HeaderWrapper>

      <Drawer
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        anchor={placement}
        overrides={{
          Root: {
            style: () => {
              return { zIndex: 9999 };
            },
          },

          Close: {
            style: {
              width: "30px",
              height: "30px",
              ":focus": {
                borderTopColor: "transparent",
                borderRightColor: "transparent",
                borderBottomColor: "transparent",
                borderLeftColor: "transparent",
              },
            },
          },
          DrawerContainer: {
            style: {
              width: "75%",
              overflow: "hidden",
            },
          },
        }}
      >
        <Block
          as="div"
          overrides={{
            Block: {
              style: ({ $theme }) => {
                return {
                  display: "flex",
                  height: "100%",
                  flexDirection: "column",
                };
              },
            },
          }}
        >
          <Block>
            <MainMenu
              stripeAccount={connectedAccount?.details_submitted ? true : false}
              className={`mobile-menu `}
              onClick={() => setIsOpen(false)}
            />
          </Block>
        </Block>
      </Drawer>
    </Sticky>
  );
};

export default MobileHeader;
