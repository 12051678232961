import React from "react";
import Link from "next/link";
import Menu, { MenuItem, MenuLink } from "./TopMenu.style";

const menuItems = [
  {
    id: 1,
    label: "Dashboard",
    path: "/admin/dashboard",
  },
  {
    id: 2,
    label: "Subscriptions",
    path: "/admin/subscriptions",
  },
  {
    id: 3,
    label: "Account Settings",
    path: "/admin/account-settings",
  },
  {
    id: 4,
    label: "Integrations",
    path: "/admin/integrations",
  },
  {
    id: 5,
    label: "Payments",
    path: "/admin/payments",
  },
  {
    id: 6,
    label: "Employees",
    path: "/admin/employees",
  },
  {
    id: 7,
    label: "Analytics",
    path: "/admin/analytics",
  },
];

type MenuProps = {
  className?: string;
  onClick?: () => void;
};

const TopMenu = ({ className, onClick }: MenuProps) => {
  return (
    <Menu className={className}>
      {menuItems.map((item) => (
        <MenuItem key={`top-menu--item${item.id}`} onClick={onClick}>
          <Link href={item.path}>
            <MenuLink>{item.label}</MenuLink>
          </Link>
        </MenuItem>
      ))}
    </Menu>
  );
};

export default TopMenu;
