import React, { useState, useEffect, useContext, createContext } from "react";

import {
  getDoc,
  storage,
  db,
  doc,
  updateDoc,
  collection,
} from "../firebase/config";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";

const useVender = (tenantId, clientId) => {
  const [vender, setVender] = useState(null);

  useEffect(() => {
    const getVenderData = async (tenantId, clientId) => {
      const venderRef = doc(db, `shops/${tenantId}/vendors/${clientId}`);
      const venderSnapshot = await getDoc(venderRef);
      const venderData = venderSnapshot.data();
      const venderList = [];
      venderList.push(venderData);
      return venderList[0];
    };

    if ((tenantId, clientId)) {
      getVenderData(tenantId, clientId).then((data) => {
        setVender(data);
      });
    }
  }, [tenantId, clientId]);

  const uploadImageProduct = async (file) => {
    try {
      if (file.type === "image/jpeg" || file.type === "image/png") {
        const imageRef = ref(storage, `images/product/${file.name}`);
        const res = await uploadBytes(imageRef, file);
        const url = await getDownloadURL(res.ref);
        console.log({ url });
        return { error: false, data: url };
      }
    } catch (error) {
      return { error: true, data: error };
    }
  };

  const updateVender = async (tenantId, clientId, data) => {
    console.log("-----", { data });
    try {
      if (typeof data.logo !== "string") {
        const photoURL = await uploadImageProduct(data.logo);
        if (photoURL.error === false) {
          data.logo = photoURL.data;
        }
      }
      if (typeof data.backgroundImage !== "string") {
        const photoURL = await uploadImageProduct(data.backgroundImage);
        if (photoURL.error === false) {
          data.backgroundImage = photoURL.data;
        }
      }

      // if the data is undefined, it will be deleted
      Object.keys(data).forEach((key) => {
        if (data[key] === undefined) {
          delete data[key];
        }
      });

      let res = await updateDoc(
        doc(db, `shops/${tenantId}/vendors/${clientId}`),
        data,
        {
          merge: true,
        }
      );

      return { error: false, data: "res" };
    } catch (e) {
      console.error("Error adding document: ", e);
      return { error: true, data: e };
    }
  };

  return {
    vender,
    venderUpdate: updateVender,
  };
};

export default useVender;
