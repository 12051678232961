import React, { useEffect, useState } from "react";
import Layout from "./Layout/Layout";
import { useRouter } from "next/router";
import { useAuth } from "../context/useAuth";

function ProtectedRoute({ Component, pageProps }) {
  const { user, loading } = useAuth();
  const router = useRouter();

  if (pageProps.protected && loading) {
    return (
      <Layout>
        <span className="loading">Loading...</span>
      </Layout>
    );
  }

  if (user === false) {
    if (pageProps.protected) {
      const loginRoute = router.route.includes("/admin")
        ? "/business/login"
        : "/login";
      router.push(loginRoute);
    }
  }

  // if subdomain

  return (
    <Layout {...pageProps}>
      <Component {...pageProps} />
    </Layout>
  );
}

export default ProtectedRoute;
