import React, { useState, useEffect, useContext, createContext } from "react";
import nookies from "nookies";
import {
  query,
  limit,
  signInWithCustomToken,
  where,
  updateDoc,
  fetchSignInMethodsForEmail,
  sendEmailVerification,
  signInWithEmailAndPassword,
  EmailAuthProvider,
  auth,
  signInAnonymously,
  signInWithEmailLink,
  signInWithPhoneNumber,
  sendSignInLinkToEmail,
  isSignInWithEmailLink,
  linkWithCredential,
  collection,
  sendPasswordResetEmail,
  updatePassword,
  getDocs,
  db,
  addDoc,
  setDoc,
  doc,
} from "../firebase/config";
import { v4 as uuidv4 } from "uuid";
import { useRouter } from "next/router";

const AuthContext = createContext();

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => {
  return useContext(AuthContext);
};

// Provider hook that creates auth object and handles state
export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [authError, setAuthError] = useState(null);
  const router = useRouter();
  const createUserWithEmailAndPassword = (email, password) => {
    return createUserWithEmailAndPassword(auth, email, password).then(
      (result) => {
        setUser(result.user);
        return true;
      }
    );
  };

  const signInAnonymouslyAuth = async (data) => {
    try {
      let anonymousLogin = await signInAnonymously(auth);
      const email = data.email;
      // link anonymous user with email
      await linkWithCredential(
        anonymousLogin.user,
        EmailAuthProvider.credential(email)
      );
      window.localStorage.setItem("emailForSignIn", email);
      // redirect domains

      // send email link to user so that they can login with email and password
      const actionCodeSettings = {
        url: `https://${process.env.NEXT_PUBLIC_VERCEL_URL}/verify`,
        handleCodeInApp: true,
      };

      sendSignInLinkToEmail(auth, email, actionCodeSettings).then((result) => {
        window.localStorage.setItem("emailForSignIn", email);
      });

      // await sendEmailVerification(anonymousLogin.user);

      let tenantId = uuidv4();
      const clientIdRef = doc(collection(db, `shops/${tenantId}/vendors`));
      const domainRef = doc(collection(db, `domains`));

      await setDoc(clientIdRef, {
        adminEmail: email,
        tenantId: tenantId,
        clientId: clientIdRef.id,
        domainRef: domainRef.id,
        domain: data.domain,
      });

      await setDoc(domainRef, {
        tenantId: tenantId,
        clientId: clientIdRef.id,
        domain: data.domain,
        live: false,
        stripeAccount: false,
      });

      const stripeCustomer = await fetch(
        `https://api.stripe.com/v1/customers`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${process.env.STRIPE_SECRET_KEY}`,
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: `email=${email}`,
        }
      ).then((res) => res.json());

      await setDoc(doc(db, `users/${anonymousLogin.user.uid}`), {
        email: email,
        tenantId: tenantId,
        clientId: [clientIdRef.id],
        defaultClientId: clientIdRef.id,
        role: "admin",
        uid: anonymousLogin.user.uid,
        stripeCustomer: stripeCustomer.id,
      });

      // onboarding
      const onBoardingRef = doc(collection(db, `shops/${tenantId}/onboarding`));
      await setDoc(onBoardingRef, {
        ...data,
      });

      await addDoc(collection(db, "products"), {
        tenantId: tenantId,
        clientId: clientIdRef.id,
        name: data.subscriptionName,
        billingPeriod: [data.billingPeriod],
        price: data.price,
        live: false,
        image: data.sellingType.imageURL,
      });

      setUser(anonymousLogin);
      setCurrentUser({
        email: email,
        tenantId: tenantId,
        clientId: [clientIdRef.id],
        defaultClientId: clientIdRef.id,
        role: "admin",
        uid: anonymousLogin.user.uid,
      });
      return true;
    } catch (error) {
      setUser(null);
      return {
        error: error.message,
      };
    }
  };

  const updateVendor = async ({ stripeAccount }) => {
    await updateDoc(
      doc(
        db,
        `shops/${currentUser.tenantId}/vendors/${currentUser.defaultClientId}`
      ),
      {
        stripeAccount: stripeAccount,
      }
    );
  };

  const emailSingUp = async (email) => {
    try {
      const actionCodeSettings = {
        url: `https://${process.env.NEXT_PUBLIC_VERCEL_URL}/verifyEmail`,

        handleCodeInApp: true,
      };

      sendSignInLinkToEmail(auth, email, actionCodeSettings).then((result) => {
        window.localStorage.setItem("emailForSignIn", email);

        return true;
      });
    } catch (error) {
      return {
        error: error.message,
      };
    }
  };

  const verifyEmailLink = async (email, link, userData) => {
    try {
      if (isSignInWithEmailLink(auth, link)) {
        // let email = "basib51533@dewareff.com";
        // window.localStorage.getItem("emailForSignIn");
        if (!email) {
          email = window.prompt("Please provide your email for confirmation");
        }
        signInWithEmailLink(auth, email, link).then((result) => {
          fetch("/api/auth/update_user", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              ...userData,
              uid: result.user.uid,
            }),
          })
            .then((res) => res.json())
            .then((data) => {
              setUser(result.user);
            });
        });

        // window.localStorage.removeItem("emailForSignIn");
        return true;
      }
    } catch (error) {
      return {
        error: error.message,
      };
    }
  };

  const phoneSingUp = async (phone, query) => {
    try {
      const appVerifier = window.recaptchaVerifier;
      // add Hostname to firebase Authorized domains in firebase domain

      await signInWithPhoneNumber(auth, phone, appVerifier).then(
        (confirmationResult) => {
          window.confirmationResult = confirmationResult;
          query
            ? router.push({
                pathname: "/shop/verify_phone",
                query: {
                  ...query,
                },
              })
            : router.push("/shop/verify_phone");
        }
      );
    } catch (error) {
      return {
        error: error.message,
      };
    }
  };

  const resetPassword = async (email) => {
    try {
      await sendPasswordResetEmail(auth, email);
      return true;
    } catch (error) {
      return {
        error: error.message,
      };
    }
  };

  const reSendEmailVerification = async (email) => {
    try {
      const actionCodeSettings = {
        url: `https://${process.env.NEXT_PUBLIC_VERCEL_URL}/verify`,
        handleCodeInApp: true,
      };

      sendSignInLinkToEmail(auth, email, actionCodeSettings).then((result) => {
        window.localStorage.setItem("emailForSignIn", email);
        return true;
      });
    } catch (error) {
      return {
        error: error.message,
      };
    }
  };

  const addCustomer = async (data) => {
    let ref = doc(db, `users/${data.uid}`);
    await setDoc(ref, {
      ...data,
    });
    return true;
  };

  const domainUpdate = async ({ stripeAccount }) => {
    const domainQuery = query(
      collection(db, "domains"),
      where("tenantId", "==", currentUser.tenantId),
      limit(1)
    );
    const querySnapshot = await getDocs(domainQuery);
    const domainDoc = querySnapshot.docs[0].id;

    await updateDoc(doc(db, `domains/${domainDoc}`), {
      stripeAccount: stripeAccount,
    });
  };

  const updateProfileAuth = async (name, password) => {
    try {
      const user = auth.currentUser;

      await updatePassword(user, password);
      //  update user display name and make email verified
      await updateProfile(user, {
        displayName: name,
        emailVerified: true,
      });
    } catch (error) {
      console.log("error", error);
    }
  };

  const logout = () => {
    return auth.signOut().then(() => {
      setUser(false);
      return true;
    });
  };

  const login = async (email, password) => {
    try {
      let res = await signInWithEmailAndPassword(auth, email, password);

      const userData = query(
        collection(db, "users"),
        where("uid", "==", res.user.uid),
        limit(1)
      );
      const querySnapshot = await getDocs(userData);
      setUser(res.user);
      setCurrentUser(querySnapshot.docs[0].data());

      return {
        error: false,
        data: querySnapshot.docs[0].data(),
      };
    } catch (error) {
      logout();
      return { error: true, data: error };
    }
  };

  // fetchSignInMethodsForEmail
  const checkIfEmailExistsInFirebaseAuth = async (email) => {
    try {
      // check if email is already in use in firebase auth
      let res = await fetchSignInMethodsForEmail(auth, email);
      return res;
    } catch (error) {
      console.log("error", error);
    }
  };

  // check if phone is already in use in firebase auth
  const checkIfUserExists = async (uid) => {
    try {
      const userData = query(
        collection(db, "users"),
        where("uid", "==", uid),
        limit(1)
      );
      const querySnapshot = await getDocs(userData);
      // if user exists return user data else return false
      return querySnapshot.docs[0] ? true : false;
    } catch (error) {
      return false;
      console.log("error", error);
    }
  };

  const setCurrentUserData = async (uid) => {
    try {
      const userData = query(
        collection(db, "users"),
        where("uid", "==", uid),
        limit(1)
      );
      const querySnapshot = await getDocs(userData);
      setCurrentUser(querySnapshot.docs[0].data());
    } catch (error) {
      console.log("error", error);
    }
  };

  const CustomTokensingIn = async (token) => {
    try {
      const res = await signInWithCustomToken(auth, token);
      console.log("res --->>>", res.user.uid, res.user);
      const userData = query(
        collection(db, "users"),
        where("uid", "==", res.user.uid),
        limit(1)
      );
      const querySnapshot = await getDocs(userData);
      setUser(res.user);
      setCurrentUser(querySnapshot.docs[0].data());
      return {
        error: false,
        data: querySnapshot.docs[0].data(),
      };
    } catch (error) {
      logout();
      return { error: true, data: error };
    }
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        setUser(user);
        const token = await user.getIdToken();
        nookies.set(undefined, "token", token, { path: "/" });
        user.uid && setCurrentUserData(user.uid);
      } else {
        setUser(false);
        nookies.set(undefined, "token", "", { path: "/" });
      }
      setIsAuthenticating(false);
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  const values = {
    user,
    currentUser,
    isAuthenticating,
    logout,
    login,
    setCurrentUserData,
    signInAnonymouslyAuth,
    updateProfileAuth,
    authError,
    checkIfEmailExistsInFirebaseAuth,
    updateVendor,
    domainUpdate,
    addCustomer,
    phoneSingUp,
    emailSingUp,
    resetPassword,
    verifyEmailLink,
    CustomTokensingIn,
    checkIfUserExists,
    reSendEmailVerification,
  };

  return (
    <AuthContext.Provider value={values}>
      {!isAuthenticating && children}
    </AuthContext.Provider>
  );
};
