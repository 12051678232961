import React from "react";
import Sticky from "react-stickynode";
import Badge from "../../UiElements/Badge/Badge";
import Logo from "../../UiElements/Logo/Logo";
import SvgIcon from "../../UiElements/SvgIcon/SvgIcon";
import Container from "../../UiElements/Container/Container";
import TopMenu from "../HeaderMenu/TopMenu/TopMenu";
import MainMenuShop from "../HeaderMenu/MainMenu/MainMenuShop";
import AvatarMenu from "../HeaderMenu/AvatarMenu/AvatarMenu";

import HeaderWrapper, {
  Navbar,
  StickyNav,
  NavLeft,
} from "./DefaultHeader.styled";

const DefaultHeader: React.FC<{}> = () => {
  return (
    <HeaderWrapper className="default">
      <MainMenuShop className="main-nav" />
    </HeaderWrapper>
  );
};

export default DefaultHeader;
