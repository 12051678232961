import { initializeApp } from "firebase/app";
import {
  getAuth,
  createUserWithEmailAndPassword,
  updateProfile,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut,
  signInWithCustomToken,
  deleteUser,
  sendPasswordResetEmail,
  RecaptchaVerifier,
  browserLocalPersistence,
  setPersistence,
  sendSignInLinkToEmail,
  signInWithEmailLink,
  linkWithCredential,
  sendEmailVerification,
  isSignInWithEmailLink,
  updatePassword,
  EmailAuthProvider,
  signInAnonymously,
  fetchSignInMethodsForEmail,
  signInWithPhoneNumber,
} from "firebase/auth";
import { ref, getStorage } from "firebase/storage";
import {
  getFirestore,
  updateDoc,
  getDoc,
  collection,
  getDocs,
  addDoc,
  deleteDoc,
  serverTimestamp,
  doc,
  setDoc,
  orderBy,
  limit,
  onSnapshot,
  query,
  where,
  arrayUnion,
} from "firebase/firestore";

const firebaseConfig = {
  apiKey: process.env.FIREBASE_API_KEY,
  authDomain: process.env.FIREBASE_AUTH_DOMAIN,
  projectId: process.env.FIREBASE_PROJECT_ID,
  storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.FIREBASE_APP_ID,
};

const app = initializeApp(firebaseConfig);
const storage = getStorage(app);

const auth = getAuth(app);
const db = getFirestore();

export {
  storage,
  auth,
  db,
  deleteUser,
  getDoc,
  createUserWithEmailAndPassword,
  updateProfile,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut,
  collection,
  getDocs,
  addDoc,
  setDoc,
  deleteDoc,
  serverTimestamp,
  doc,
  orderBy,
  updateDoc,
  limit,
  onSnapshot,
  query,
  browserLocalPersistence,
  setPersistence,
  where,
  sendSignInLinkToEmail,
  signInWithEmailLink,
  signInAnonymously,
  linkWithCredential,
  sendEmailVerification,
  RecaptchaVerifier,
  updatePassword,
  EmailAuthProvider,
  fetchSignInMethodsForEmail,
  signInWithPhoneNumber,
  arrayUnion,
  sendPasswordResetEmail,
  isSignInWithEmailLink,
  signInWithCustomToken,
};
