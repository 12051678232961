import React, { useState } from "react";
import ActiveLink from "../../../UiElements/NavLink/NavLink";
import Menu, { MenuItem, MenuLink } from "./MainMenu.styled";
import { FiLogOut, FiSettings, FiActivity, FiHome } from "react-icons/fi";
import { AiFillShop, AiOutlineCreditCard } from "react-icons/ai";
import { IoIosPeople, IoMdPeople } from "react-icons/io";
import { Block } from "baseui/block";
import { BsFillExclamationTriangleFill } from "react-icons/bs";
import { useAuth } from "../../../../context/useAuth";

import { PageTitle } from "../../MobileHeader/MobileHeader.styled";
import { useRouter } from "next/router";
import { BsShop } from "react-icons/bs";
import { Button } from "baseui/button";
import useVender from "../../../../hooks/useVender";
import SubscriptionPlans from "../../../SubscriptionPlans.js";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton,
  SIZE,
  ROLE,
} from "baseui/modal";

const menuItems = [
  {
    id: 1,
    label: "Dashboard",
    path: "/admin/dashboard",
    icon: <FiHome />,
  },
  {
    id: 2,
    label: "Subscriptions",
    path: "/admin/subscriptions",
    icon: <IoMdPeople />,
  },
  {
    id: 3,
    label: "Account Settings",
    path: "/admin/account-settings",
    icon: <FiSettings />,
  },
  {
    id: 4,
    label: "Shop Settings",
    path: "/admin/shop-settings",
    icon: <AiFillShop />,
  },
  {
    id: 5,
    label: "Payments",
    path: "/admin/payments",
    icon: <AiOutlineCreditCard />,
    alertIcon: <BsFillExclamationTriangleFill />,
  },
  {
    id: 6,
    label: "Customers",
    path: "/admin/customers",
    icon: <IoIosPeople />,
  },
  {
    id: 6,
    label: "Employees",
    path: "/admin/employees",
    icon: <IoMdPeople />,
  },
  {
    id: 7,
    label: "Analytics",
    path: "/admin/analytics",
    icon: <FiActivity />,
  },
];
type MenuProps = {
  className?: string;
  onClick?: () => void;
  stripeAccount?: any;
};

const MainMenu = ({ className, onClick, stripeAccount }: MenuProps) => {
  const { logout } = useAuth();
  const { currentUser } = useAuth();
  const vender = useVender(currentUser?.tenantId, currentUser?.defaultClientId);

  const [openSubscriptionModal, setOpenSubscriptionModal] = useState(false);

  // get the height of the device

  const { pathname } = useRouter();

  let pageName: React.ReactNode = pathname.split("/").slice(1, 2);
  let titleColor = "#000000";

  pageName =
    pathname !== "/" && pathname !== "/_error" ? (
      <PageTitle $style={{ color: titleColor }}>{pageName}</PageTitle>
    ) : null;

  return (
    <Block
      overrides={{
        Block: {
          style: ({ $theme }) => {
            return {
              display: "flex",
              flexDirection: "column",
              height: "100%",
              position: "fixed",
            };
          },
        },
      }}
    >
      <Menu className={className}>
        <Block className="default-header-logo">
          <MenuLink>monthlysubs.co</MenuLink>
        </Block>
        {menuItems.map((item) => (
          <MenuItem key={`top-menu--item${item.id}`} onClick={onClick}>
            <ActiveLink activeClassName="active" href={item.path}>
              <MenuLink>
                <Block
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  marginRight="scale200"
                >
                  {item.icon}
                </Block>
                {item.label}
                {item.alertIcon && stripeAccount == false && (
                  <BsFillExclamationTriangleFill
                    style={{
                      color: "#d8c200",
                      marginLeft: "5px",
                      fontSize: "20px",
                    }}
                  />
                )}
              </MenuLink>
            </ActiveLink>
          </MenuItem>
        ))}
      </Menu>
      <Block
        overrides={{
          Block: {
            style: ({ $theme }) => {
              return {
                display: "flex",
                flexDirection: "column",

                [`${$theme.mediaQuery.medium}`]: {
                  padding: "0 34px",
                },

                margin: window.innerHeight < 700 ? "0" : "auto",
              };
            },
          },
        }}
      >
        <Button
          overrides={{
            BaseButton: {
              style: ({ $theme }) => {
                return {
                  width: "100%",
                  backgroundColor: "#1E88E5",
                  color: $theme.colors.white,
                  borderRadius: "8px",
                };
              },
            },
          }}
          onClick={() => setOpenSubscriptionModal(true)}
        >
          Upgrade to Pro
        </Button>
        <Modal
          onClose={() => setOpenSubscriptionModal(false)}
          closeable
          isOpen={openSubscriptionModal}
          animate
          autoFocus
          size={SIZE.default}
          role={ROLE.dialog}
        >
          <ModalBody>
            <SubscriptionPlans />
          </ModalBody>
          <ModalFooter>
            <ModalButton>Okay</ModalButton>
          </ModalFooter>
        </Modal>
        <Block
          overrides={{
            Block: {
              style: ({ $theme }) => {
                return {
                  color: $theme.colors.white,
                };
              },
            },
          }}
        >
          <a
            style={{ color: "white", textDecoration: "none" }}
            href={`https://${vender.vender?.domain}.${process.env.NEXT_PUBLIC_VERCEL_URL}/shop`}
            target="_blank"
          >
            <Block
              as="div"
              // onclick to redirect www.google.com

              overrides={{
                Block: {
                  style: ({ $theme }) => {
                    return {
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                      backgroundColor: $theme.colors.primaryB,
                      padding: "10px",
                      cursor: "pointer",
                    };
                  },
                },
              }}
            >
              <BsShop />
              <Block
                as="div"
                overrides={{
                  Block: {
                    style: ({ $theme }) => {
                      return {
                        marginLeft: "10px",
                      };
                    },
                  },
                }}
              >
                View subscription Page
              </Block>
            </Block>
          </a>
          <Block
            onClick={() => {
              logout();
            }}
            as="div"
            overrides={{
              Block: {
                style: ({ $theme }) => {
                  return {
                    display: "flex",
                    alignItems: "center",
                    // justifyContent: "center",
                    width: "100%",
                    backgroundColor: $theme.colors.primaryB,
                    padding: "10px",
                    cursor: "pointer",
                  };
                },
              },
            }}
          >
            <FiLogOut />
            <Block
              as="div"
              overrides={{
                Block: {
                  style: ({ $theme }) => {
                    return {
                      marginLeft: "10px",
                    };
                  },
                },
              }}
            >
              Logout
            </Block>
          </Block>
        </Block>
      </Block>
    </Block>
  );
};

export default MainMenu;
