import React, { useEffect, useState } from "react";

import { Block } from "baseui/block";
import { Button } from "baseui/button";
import { loadStripe } from "@stripe/stripe-js";
import { useRouter } from "next/router";
import { useAuth } from "../context/useAuth";
const SubscriptionPlans = () => {
  const [plans, setPlans] = useState([]);
  const { user, currentUser } = useAuth();

  const router = useRouter();

  useEffect(() => {
    // call api to get subscription plans
    const SubscriptionPlan = async () => {
      const res = await fetch("/api/product/get_subscription_product");
      const data = await res.json();

      setPlans(data);
    };

    SubscriptionPlan();
  }, []);

  const createSubscription = async (priceId) => {
    // create subscription
    const res = await fetch(`/api/subscription/${priceId}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        stripe_customer_id: currentUser.stripeCustomer,
      }),
    });
    const data = await res.json();
    const stripe = await loadStripe(process.env.STRIPE_PUBLISHABLE_KEY);
    await stripe.redirectToCheckout({ sessionId: data.sessionId });
  };

  const manageSubscription = async () => {
    const res = await fetch(`/api/portal`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        customerId: currentUser.stripeCustomer,
      }),
    });
    const data = await res.json();

    // take to the data.url to manage subscription
    router.push(data.data.url);
  };

  console.log(currentUser);

  return (
    <div>
      <Block
        display="flex"
        flexDirection="row"
        justifyContent={window.innerHeight < 700 ? "center" : "space-between"}
      >
        {plans.map((plan) => (
          <Block key={plan.priceId}>
            <Block>{plan.name}</Block>
            <Block>
              ${plan.price / 100}/{plan.interval}
            </Block>
            {currentUser.subscription?.plan === plan.priceId && (
              <Block color="green">Current Plan</Block>
            )}
            {!currentUser.subscription && (
              <Button onClick={() => createSubscription(plan.priceId)}>
                Subscribe
              </Button>
            )}
            {currentUser.subscription?.plan == plan.priceId && (
              <Button
                onClick={() => {
                  manageSubscription();
                }}
              >
                Manage Subscription
              </Button>
            )}
          </Block>
        ))}
      </Block>
    </div>
  );
};

export default SubscriptionPlans;
