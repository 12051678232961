import React, { useEffect, useState } from "react";
import "../assets/css/reset.css";
import "react-toastify/dist/ReactToastify.css";
import { Provider as StyletronProvider } from "styletron-react";
import { LightTheme, BaseProvider, DarkTheme } from "baseui";
import { AuthProvider } from "../context/useAuth";
import { ProductProvider } from "../context/useProduct";
import { SubscriptionProvider } from "../context/useSubscription";
import TopBarProgress from "react-topbar-progress-indicator";

import { styletron } from "../styletron";
import ProtectedRoute from "../components/ProtectedRoute";
import Router, { useRouter } from "next/router";

const MyApp = ({ Component, pageProps }) => {
  const [progress, setProgress] = useState(false);

  Router.events.on("routeChangeStart", () => setProgress(true));
  Router.events.on("routeChangeComplete", () => setProgress(false));
  TopBarProgress.config({
    barColors: {
      "0": "#2563eb",
      "1.0": "#2563eb",
    },

    shadowBlur: 5,
  });

  return (
    <>
      <StyletronProvider value={styletron}>
        <BaseProvider theme={DarkTheme}>
          <AuthProvider>
            <ProductProvider>
              <SubscriptionProvider>
                {progress && <TopBarProgress />}
                <ProtectedRoute Component={Component} pageProps={pageProps} />
              </SubscriptionProvider>
            </ProductProvider>
          </AuthProvider>
        </BaseProvider>
      </StyletronProvider>
    </>
  );
};

export default MyApp;
