import { styled } from "baseui";

const AppWrapper = styled("main", {
  width: "100%",
  display: "flex",
  minHeight: "100vh",

  backgroundColor: "black",
  // flexDirection column if desktop, row if mobile
  flexDirection: "row",
  "@media only screen and (max-width: 1135px)": {
    flexDirection: "column",
  },
});

export const ContentWrapper = styled("div", ({ $theme }) => ({
  flex: "1 1 auto",
  overflow: "hidden",
  paddingBottom: $theme.sizing.scale1400,
  "@media only screen and (max-width: 1135px)": {
    paddingBottom: $theme.sizing.scale1000,
  },
  "@media only screen and (max-width: 767px)": {
    paddingBottom: $theme.sizing.scale900,
  },
}));

export default AppWrapper;
