import React from "react";
import DefaultHeaderShop from "./DefaultHeader/DefaultHeaderShop";
import MobileHeader from "./MobileHeaderShop/MobileHeader";

import HeaderWrapper, { HideInMobile, ShowInMobile } from "./Header.styled";

const Header: React.FC<{}> = () => {
  return (
    <HeaderWrapper>
      <HideInMobile>
        <DefaultHeaderShop />
      </HideInMobile>
      <ShowInMobile>
        <MobileHeader />
      </ShowInMobile>
    </HeaderWrapper>
  );
};

export default Header;
