import { styled } from "baseui";

const Menu = styled("ul", {
  display: "flex",
  flexDirection: "column",
  transition: "padding 0.25s ease",
  "@media only screen and (min-width: 1135px)": {
    width: "100%",
    flexDirection: "column",
  },
});

export const MenuItem = styled("li", ({ $theme }) => ({
  listStyle: "none",
  "@media only screen and (max-width: 1135px)": {
    margin: 0,
    paddingTop: "8px",
    paddingBottom: "8px",
    borderBottom: `1px solid ${$theme.colors.backgroundTertiary}`,
  },
  "@media only screen and (min-width: 1135px)": {
    paddingLeft: "16px",
    borderBottom: `1px solid ${$theme.colors.backgroundTertiary}`,
  },
}));

export const MenuLink = styled("a", ({ $theme }) => ({
  display: "inline-flex",
  color: $theme.colors.contentSecondary,
  textDecoration: "none",
  padding: `${$theme.sizing.scale600} 0`,
  cursor: "pointer",
  transition: "all 0.3s ease",
  ...$theme.typography.font200,
  ":hover": {
    color: $theme.colors.primary,
  },
  "@media only screen and (max-width: 1135px)": {
    paddingTop: "8px",
    paddingBottom: "8px",
    paddingLeft: "20px",
    paddingRight: "20px",
    width: "100%",
    ...$theme.typography.font300,
    ":hover": {
      backgroundColor: $theme.colors.backgroundSecondary,
    },
  },
  "@media only screen and (min-width: 1135px)": {
    width: "70%",
  },
}));

export default Menu;
