import React, { useState } from "react";
import MainMenu from "../HeaderMenu/MainMenu/MainMenu";

import HeaderWrapper from "./DefaultHeader.styled";
import { useRouter } from "next/router";
import { useAuth } from "../../../context/useAuth";

import useVender from "../../../hooks/useVender";
import { getDomainInfo } from "../../../helpers";

const DefaultHeader: React.FC<{}> = () => {
  const { currentUser } = useAuth();
  const vender = useVender(currentUser?.tenantId, currentUser?.defaultClientId);
  const [connectedAccount, setConnectedAccount] = React.useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [menu, setMenu] = useState("main");
  const { pathname } = useRouter();

  React.useEffect(() => {
    const getConnectedAccount = async () => {
      const DomainInfo = await getDomainInfo(currentUser?.defaultClientId);
      if (currentUser && DomainInfo?.stripeAccount) {
        // verify if the account is connected
        fetch("/api/payment/connected_account", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            stripeAccount: DomainInfo?.stripeAccount,
          }),
        })
          .then((res) => res.json())
          .then((data) => {
            setConnectedAccount(data);
          });
      }
    };
    getConnectedAccount();
  }, [currentUser]);
  return (
    <HeaderWrapper className="default">
      <MainMenu
        stripeAccount={connectedAccount?.details_submitted ? true : false}
        className="main-nav"
      />
    </HeaderWrapper>
  );
};

export default DefaultHeader;
