import React, { useState } from "react";
import { FiLogOut, FiMenu, FiMoreHorizontal, FiHome } from "react-icons/fi";
import Sticky from "react-stickynode";
import { useRouter } from "next/router";
import { Button } from "baseui/button";
import { Drawer } from "baseui/drawer";
import { Block } from "baseui/block";

import { BsShop } from "react-icons/bs";
import TopMenu from "../HeaderMenu/TopMenu/TopMenu";
import MainMenu from "../HeaderMenu/MainMenu/MainMenuShop";

import { useAuth } from "../../../context/useAuth";
import HeaderWrapper, {
  PageTitle,
  TopMenuWrapper,
} from "./MobileHeader.styled";

const MobileHeader: React.FC<{}> = () => {
  const { logout } = useAuth();
  const [isOpen, setIsOpen] = useState(false);
  const [menu, setMenu] = useState("main");
  const { pathname } = useRouter();

  let pageName: React.ReactNode = pathname.split("/").slice(1, 2);
  let titleColor = "#000000";

  pageName =
    pathname !== "/" && pathname !== "/_error" ? (
      <PageTitle $style={{ color: titleColor }}>{pageName}</PageTitle>
    ) : // <Logo
    //   style={{ marginTop: "3px" }}
    //   path="/"
    //   src={
    //     <SvgIcon src={require("../../../assets/images/logo.svg?include")} />
    //   }
    // />
    null;

  let placement;
  if (menu === "main") {
    placement = "left";
  }
  if (menu === "top") {
    placement = "right";
  }

  return (
    <Sticky>
      <HeaderWrapper className="mobile">
        <Button
          onClick={() => {
            setIsOpen(true);
            setMenu("main");
          }}
          kind="minimal"
          size="compact"
          overrides={{
            BaseButton: {
              style: ({ $theme }) => {
                return {
                  fontSize: "22px",
                  //   width: "30px",
                  justifyContent: "flex-start",
                  backgroundColor: "transparent",
                  position: "absolute",
                  left: "0",
                  top: "12px",
                  color: $theme.colors.white,
                  ":hover": {
                    backgroundColor: $theme.colors.primaryB,
                  },
                  ":focus": {
                    backgroundColor: $theme.colors.primaryB,
                  },
                };
              },
            },
          }}
        >
          <FiMenu />
        </Button>

        <PageTitle> Welcome !</PageTitle>
      </HeaderWrapper>

      <Drawer
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        anchor={placement}
        overrides={{
          Root: {
            style: () => {
              return { zIndex: 9999 };
            },
          },

          Close: {
            style: {
              width: "30px",
              height: "30px",
              ":focus": {
                borderTopColor: "transparent",
                borderRightColor: "transparent",
                borderBottomColor: "transparent",
                borderLeftColor: "transparent",
              },
            },
          },
          DrawerContainer: {
            style: {
              width: "75%",
              overflow: "hidden",
            },
          },
        }}
      >
        {menu === "main" && (
          <MainMenu
            className={`mobile-menu `}
            onClick={() => setIsOpen(false)}
          />
        )}

        {menu === "top" && (
          <TopMenuWrapper>
            <TopMenu onClick={() => setIsOpen(false)} />
          </TopMenuWrapper>
        )}

        <Block
          overrides={{
            Block: {
              style: ({ $theme }) => {
                return {
                  display: "flex",
                  color: $theme.colors.white,
                  flexDirection: "column",
                  alignItems: "center",
                  position: "absolute",
                  bottom: "84px",
                  left: "37px",
                  // backgroundColor: $theme.colors.primary,
                };
              },
            },
          }}
        >
          <Block
            onClick={() => {
              logout();
            }}
            as="div"
            overrides={{
              Block: {
                style: ({ $theme }) => {
                  return {
                    display: "flex",
                    alignItems: "center",
                    // justifyContent: "center",
                    width: "100%",
                    backgroundColor: $theme.colors.primaryB,
                    padding: "10px",
                    cursor: "pointer",
                  };
                },
              },
            }}
          >
            <FiLogOut />
            <Block
              as="div"
              overrides={{
                Block: {
                  style: ({ $theme }) => {
                    return {
                      marginLeft: "10px",
                    };
                  },
                },
              }}
            >
              Logout
            </Block>
          </Block>
        </Block>
      </Drawer>
    </Sticky>
  );
};

export default MobileHeader;
